<template>
  <div>
    <q-form ref="editForm">
      <c-card title="기본정보" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable&&isOld"
              label="삭제"
              icon="delete"
              @btnClicked="deleteInfo" />
            <c-btn
              v-show="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="plan"
              :mappingType="mappingType"
              label="저장"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              :required="true"
              :disabled="disabled"
              :editable="editable"
              label="평가계획명"
              name="jobStressPlanName"
              v-model="plan.jobStressPlanName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-datepicker
              :required="true"
              :disabled="disabled"
              :editable="editable"
              type="year"
              label="년도"
              name="planYear"
              v-model="plan.planYear"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-datepicker
              :required="true"
              :disabled="disabled"
              :editable="editable"
              range
              label="평가기간"
              name="period"
              v-model="plan.period"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-plant
              :required="true"
              :disabled="disabled"
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="plan.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <c-textarea
              :disabled="disabled"
              :editable="editable"
              :rows="5"
              label="비고"
              name="remarks"
              v-model="plan.remarks">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </q-form>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
        <c-table
          ref="table1"
          title="평가자 목록"
          :columns="grid.columns"
          :data="plan.users"
          :gridHeight="gridHeight"
          :filtering="false"
          :isExcelDown="false"
          :isFullScreen="false"
          :columnSetting="false"
          selection="multiple"
          rowKey="userId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addUser" />
              <c-btn v-if="editable" label="제외" icon="delete" @btnClicked="deleteUser" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
        <c-table
          ref="table2"
          title="중앙값"
          :columns="gridCenter.columns"
          :data="plan.centers"
          :gridHeight="gridHeight"
          :usePaging="false"
          :filtering="false"
          :isExcelDown="false"
          :isFullScreen="false"
          :columnSetting="false"
        >
          <template slot="suffixTitle">
            <div style="font-size:0.9em;font-weight:400;">
              <div class="text-primary" >
                <q-chip color="primary" text-color="white" outline square>
                  회사중앙값
                </q-chip>
                : 평가자들의 직무스트레스 평가표 점수 평균값
              </div>
              <div class="text-teal" >
                <q-chip color="teal" text-color="white" outline square>
                  한국 근로자 중앙값
                </q-chip>
                : 기준이 되는 한국 근로자 중앙값으로 최근 연구결과를 입력
              </div>
            </div>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'jobStressPlanDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaJobStressPlanId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      plan: {
        heaJobStressPlanId: '',  // 직무스트레스 계획 일련번호
        plantCd: null,  // 사업장
        planYear: '',  // 년도
        jobStressPlanName: '',  // 평가계획명
        assessmentStartDate: '',  // 평가 시작일
        assessmentEndDate: '',  // 평가 종료일
        period: [], // 평가기간
        remarks: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        users: [], // 평가자
        deleteUsers: [], // [삭제]평가자
        centers: [], // 중앙값
      },
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
            style: 'width: 50%',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '이름',
            align: 'center',
            sortable: true,
            style: 'width: 50%',
          },
          // {
          //   name: 'result',
          //   field: 'result',
          //   label: '평가상태',
          //   style: 'width:100px',
          //   align: "center",
          //   sortable: true,
          // },
        ],
      },
      gridCenter: {
        columns: [
          {
            name: 'heaStressCheckClassName',
            field: 'heaStressCheckClassName',
            label: '구분',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'company',
            field: 'company',
            label: '회사중앙값',
            child: [
              {
                name: 'companyMale',
                field: 'companyMale',
                label: '남성',
                align: 'right',
                sortable: true,
                style: 'width: 70px',
                type: 'cost'
              },
              {
                name: 'companyFemale',
                field: 'companyFemale',
                label: '여성',
                align: 'right',
                sortable: true,
                style: 'width: 70px',
                type: 'cost'
              },
              {
                name: 'company',
                field: 'company',
                label: '전체',
                align: 'right',
                sortable: true,
                style: 'width: 70px',
                type: 'cost'
              },
            ]
          },
          {
            name: 'korea',
            field: 'korea',
            label: '한국 근로자 중앙값',
            child: [
              {
                name: 'koreaMale',
                field: 'koreaMale',
                label: '남성',
                align: 'right',
                sortable: true,
                style: 'width: 70px',
                type: 'number'
              },
              {
                name: 'koreaFemale',
                field: 'koreaFemale',
                label: '여성',
                align: 'right',
                sortable: true,
                style: 'width: 70px',
                type: 'number'
              },
              {
                name: 'korea',
                field: 'korea',
                label: '전체',
                align: 'right',
                sortable: true,
                style: 'width: 70px',
                type: 'number'
              },
            ]
          },
        ],
      },
      editable: true,
      isSave: false,
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      mappingType: 'PUT',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.heaJobStressPlanId)
    },
    disabled() {
      return false;
    },
    gridHeight() {
      return this.contentHeight ? String(this.contentHeight - 310) + 'px' : '500px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.hea.jobStress.plan.get.url
      this.saveUrl = transactionConfig.hea.jobStress.plan.update.url
      this.deleteUrl = transactionConfig.hea.jobStress.plan.delete.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.heaJobStressPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.plan, _result.data)
          this.$set(this.plan, 'period', [this.plan.assessmentStartDate, this.plan.assessmentEndDate])
        },);
      } else {
        // 중앙값 구분자 들고 오기
        this.$comm.getComboItems('HEA_STRESS_CHECK_CLASS_CD').then(_result => {
          if (_result && _result.length > 0) {
            this.$_.forEach(_result, item => {
              this.plan.centers.push({
                heaJobStressAssessmentCenterId: uid(),  // 직무스트레스 결과 중앙값 일련번호
                heaJobStressPlanId: this.popupParam.heaJobStressPlanId,  // 직무스트레스 계획 일련번호
                heaStressCheckClassCd: item.code,  // 직무스트레스 평가항목 구분
                heaStressCheckClassName: item.codeName,  // 직무스트레스 평가항목 구분
                companyMale: null,  // 회사 중앙값 남성
                companyFemale: null,  // 회사 중앙값 여성
                company: null,  // 회사 중앙값 전체
                koreaMale: null,  // 한국 근로자 중앙값 남성
                koreaFemale: null,  // 한국 근로자 중앙값 여성
                korea: null,  // 한국 근로자 중앙값 전체
                regUserId: this.$store.getters.user.userId,  // 등록자 id
                editFlag: 'C'
              })
            })
          }
        });
      }
    },
    deleteUser() {
      let selectData = this.$refs['table1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.plan.deleteUsers) this.plan.deleteUsers = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.plan.deleteUsers, { userId: item.userId }) === -1) {
              this.plan.deleteUsers.push(item);
            }
          this.plan.users = this.$_.reject(this.plan.users, item);
        })
        this.$refs['table1'].$refs['compo-table'].clearSelection();
      }
    },
    addUser() {
      this.popupOptions.title = '사용자 검색'; 
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.plan.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.plan.users, { userId: item.userId }) === -1) {
            this.plan.users.push({
              heaJobStressAssessmentUserId: uid(),  // 직무스트레스 평가자 일련번호
              heaJobStressPlanId: this.popupParam.heaJobStressPlanId,  // 직무스트레스 계획 일련번호
              userId: item.userId,  // 평가자id
              userName: item.userName,  // 평가자
              deptCd: item.deptCd,  // 평가자부서코드
              deptName: item.deptName,  // 평가자부서
              sexCd: item.sexCd,  // 성별
              physicalEnv: null,  // (점수)물리적환경
              jobDemand: null,  // (점수)직무요구
              jobAutonomy: null,  // (점수)직무자율
              relationship: null,  // (점수)관계갈등
              jobInsecurity: null,  // (점수)직무불안정
              organizational: null,  // (점수)조직체계
              inadequate: null,  // (점수)보상부적절
              workplace: null,  // (점수)직장문화
              manageTargetGroupFlag: 'N',  // 관리대상군 여부
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        })
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.hea.jobStress.plan.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.hea.jobStress.plan.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.plan.regUserId = this.$store.getters.user.userId;
              this.plan.chgUserId = this.$store.getters.user.userId;

              this.$set(this.plan, 'assessmentStartDate', this.plan.period[0])
              this.$set(this.plan, 'assessmentEndDate', this.plan.period[1])

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'heaJobStressPlanId', result.data)
      this.getDetail();
    },
    deleteInfo() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.heaJobStressPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.$emit('closePopup')
          },);
          
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });

    }
  }
};
</script>